<template>
  <footer class="new_footer_area">
    <div class="footer_body" v-if="device === 'desktop'">
      <div class="container">
        <div class="row">
          <footer-column
            :itemList="getChainList"
            :path="`chain`"
            :caption="$t('footer.footer-list.hotel-chain')"
            :index="`chain`"
          />
          <footer-column
            :itemList="getDestinationList"
            :path="`city`"
            :caption="$t('footer.footer-list.destinations')"
            :index="`dest`"
          />
          <footer-column
            :itemList="getServiceList"
            :path="``"
            :caption="$t('footer.footer-list.customer-service')"
            :index="`service`"
          />
          <footer-tree-column
            :itemList="getStaticPages"
            :subTypeList="getStatictPageSubtypes"
            :caption="$t('footer.footer-list.goals-guide')"
          />
        </div>
      </div>
    </div>

    <div class="footer_body" v-if="device === 'mobile'">
      <div class="container">
        <footer-column-mobile
          :itemList="getChainList"
          :path="`chain`"
          :caption="$t('footer.footer-list.hotel-chain')"
          :index="`chain`"
        />
        <footer-column-mobile
          :itemList="getDestinationList"
          :path="`city`"
          :caption="$t('footer.footer-list.destinations')"
          :index="`dest`"
        />
        <footer-column-mobile
          :itemList="getServiceList"
          :path="``"
          :caption="$t('footer.footer-list.customer-service')"
          :index="`service`"
        />
        <footer-tree-column-mobile
          :itemList="getStaticPages"
          :subTypeList="getStatictPageSubtypes"
          :caption="$t('footer.footer-list.goals-guide')"
        />
      </div>
    </div>

    <div class="footer_body">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12 col-md-3">
            <div class="footer">
              <div class="footer_logo"><img src="/assets/img/logo.png" alt="#" title="" /></div>
              <h5>{{$t("footer.follow-us-on")}}</h5>
              <ul class="footer_social">
                  <li><a href="https://twitter.com/mybilvy2" target="_blank" title="Opens in a new window"><i class="fab fa-x-twitter"></i></a></li>
                  <li><a href="https://www.facebook.com/flyingisr" target="_blank" title="Opens in a new window"><i class="fab fa-facebook-f"></i></a></li>
                  <!-- <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="Opens in a new window"><i class="fab fa-google"></i></a></li> -->
                  <li><a href="https://t.me/flyingisr" target="_blank" title="Opens in a new window"><i class="fab fa-telegram"></i></a></li>
                  <li><a href="https://www.instagram.com/flyingcarpetincoming" target="_blank" title="Opens in a new window"><i class="fab fa-instagram"></i></a></li>
                  <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                  <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-6 col-sm-12 col-md-9">
            <div class="footer row">
              <div class="col-12 col-sm-6 col-md-6 text-center show-message form-group">{{$t("footer.member-login-alert")}}</div>
              <div class="col-12 col-sm-6 col-md-3 text-center">
                <!-- <member-register/> -->
              </div>
              <div class="col-12 col-sm-6 col-md-2 text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright_area"><p>{{ $t("footer.all-reserved-title", {year: new Date().getFullYear(), agencyName: whiteLabel.name}) }}</p></div>

  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

// const { VUE_APP_MAIN_DOMAIN } = process.env;

export default {
  name: 'Footer',
  components: {
    FooterColumn: () => import('./FooterColumn'),
    FooterTreeColumn: () => import('./FooterTreeColumn'),
    FooterColumnMobile: () => import('./FooterColumnMobile'),
    FooterTreeColumnMobile: () => import('./FooterTreeColumnMobile'),
  },
  computed: {
    ...mapGetters({
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      destinationList: 'GET_CAMINGO_DESTINATION',
      chainList: 'GET_HOTEL_CHAIN_TABLE',
      staticPageList: 'GET_FOOTER_PAGES_LIST',
      staticPageSubtypeList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    getChainList() {
      if (!this.chainList) return null;
      const { chainList } = this;
      const list = [];
      chainList.forEach((item) => {
        const subItem = [];
        subItem[0] = item.id;
        subItem[1] = item[`name${this.lang.charAt(0).toUpperCase()}${this.lang.slice(1)}`];
        list.push(subItem);
      });
      return list;
    },
    getDestinationList() {
      if (!this.destinationList) return null;
      const list = [];
      this.destinationList.forEach((item) => {
        const subItem = [];
        subItem[0] = item.code;
        subItem[1] = item.nameTranslations[this.lang];
        list.push(subItem);
      });
      return list;
    },
    getServiceList() {
      // const list = [
      //   [`${VUE_APP_MAIN_DOMAIN}/אודות_`, this.$t('footer.footer-list.about')],
      //   [`${VUE_APP_MAIN_DOMAIN}/צור_קשר`, this.$t('footer.footer-list.contact-us')],
      //   [`${VUE_APP_MAIN_DOMAIN}/מדיניות_פרטיות`, this.$t('footer.footer-list.to-israel')],
      //   [`${VUE_APP_MAIN_DOMAIN}/מידע_לנוסע`, this.$t('footer.footer-list.passenger-information')],
      //   [`${VUE_APP_MAIN_DOMAIN}/תקנון_ביטול_וזיכוי`, this.$t('footer.footer-list.cancel-and-credit')],
      //   [`${VUE_APP_MAIN_DOMAIN}/תנאים_כללים_והגבלת_אחריות`, this.$t('footer.footer-list.general-conditions-and-limitation-of-liability')],
      //   [`${VUE_APP_MAIN_DOMAIN}/תקנון_מועדון_לקוחות_האתר`, this.$t('footer.footer-list.site-customer-club-rules')],
      //   [`${VUE_APP_MAIN_DOMAIN}/מדריך_היעדים`, this.$t('footer.footer-list.destination-guide')],
      //   [`${VUE_APP_MAIN_DOMAIN}/בקשה_לביטול_הזמנה`, this.$t('footer.footer-list.request-to-cancel-an-order')],
      //   [`${VUE_APP_MAIN_DOMAIN}/הרשמה_לרשימת_תפוצה`, this.$t('footer.footer-list.Subscribe-to-a-mailing-list')],
      // ];
      // return list;
      if (!this.staticPageList) return [];
      const customerServiceList = Object.entries(this.staticPageList).filter((item) => item[1].category === 'Customer Service');
      return customerServiceList.map((item) => [`/${item[1].slug}`, item[1].titleTrans[this.lang] || item[1].title]);
    },
    getStatictPageSubtypes() {
      const list = [];
      if (this.staticPageSubtypeList) {
        this.staticPageSubtypeList.filter((item) => item.code !== 'Customer Service').forEach((item) => {
          const subItem = [];
          subItem[0] = item.code;
          subItem[1] = item.nameTranslationEng;
          subItem[2] = item.nameTranslationHeb;
          subItem[3] = item.nameTranslationRus;
          subItem[4] = item.nameTranslationAra;
          list.push(subItem);
        });
      }
      return list;
    },
    getStaticPages() {
      const list = [];
      if (this.staticPageList) {
        Object.entries(this.staticPageList).forEach((item) => {
          const subItem = [];
          subItem[0] = item[1].slug;
          subItem[1] = item[1].titleTrans[this.lang] || item[1].title;
          subItem[2] = item[1].category;
          list.push(subItem);
        });
      }
      return list;
    },
  },
  data() {
    return {
      landingQuery: '',
    };
  },
  mounted() {
    this.landingQuery = window.localStorage.getItem('landing-url') ? `${window.location.origin}/${window.localStorage.getItem('landing-url')}` : '';
  },
  methods: {
  },
};
</script>

<style>
  body.flat-404 footer{
    margin-bottom: 0;
    color: white;
  }
  body.flat-404 .footer-btm .copy ul li a {
    color: white;
  }
  .row_title {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px;
  }
</style>

<style scoped>
  .show-message{
    font-size: 1.5rem;
    color: white;
  }
  .link-nDetail .short-links .list-group-item{
    padding: 0;
  }
  footer .list-group,
  .link-nDetail .footer-social ul {
    padding: 0;
  }
  footer .shortLink-wrapper:not(:last-child){
    border-left: 1px solid white;
  }
  footer .shortLink-wrapper{
    padding: 0 1.5rem;
  }
  .new_footer_area{
    background-image: url('/assets/img/newfooterbg.jpg');
  }
</style>
